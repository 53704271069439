<template>
  <div class="helppublic">
   <div class="helppublic-container">
     <TitleMore
      :title='["人道救助","彩票金先心病救助","彩票金白血病救助"," 博爱送万家"]'
      @handleTab="gdHandleTab"
      :isMore="false"
    ></TitleMore>
     <div
      class="baseCard"
      v-if="tableData.length>0"
    >
      <div
        class="itemText"
        v-for="(item,index) in tableData"
        :key="index"
		style="margin-bottom: 10px;"
      >
        <p @click="$goto('/detailShow',{id:item.id})">
          <img
            src="@/assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
    </div>
     <!-- <div class="helppubliccontent">
        <h2>2020年公益项目拨款名单</h2>
        <TitleLine width="250px"></TitleLine>
        <div class="tabcontent">
         <el-table
            :data="tableData"
            border
            stripe
            style="width: 100%">
            <el-table-column
            prop="id"
            label="序号"
            width="69">
            </el-table-column>
            <el-table-column
            prop="archive_number"
            label="档案号"
            width="123">
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名"
            width="118">
            </el-table-column>
            <el-table-column
            prop="fund_amount"
            label="资助金额 (元)"
            width="118">
            </el-table-column>
            <el-table-column
            prop="remarks"
            label="备注"
            width="105">
            </el-table-column>
            <el-table-column
            prop="city"
            label="市"
            width="105">
            </el-table-column>
            <el-table-column
            prop="county"
            label="县（区）"
            width="99">
            </el-table-column>
            <el-table-column
            prop="guardian"
            label="监护人"
            width="113">
            </el-table-column>
            <el-table-column
            prop="disease_type"
            label="病型"
            min-width="228">
            </el-table-column>
        </el-table>
        </div>

     </div> -->
     <div class="pagination">
            <el-pagination
            class="bs-pagination"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
   </div>
  </div>
</template>

<script>
import apiUrls from '@api'
import TitleLine from '@/components/TitleLine'
import TitleMore from "@components/TitleMore.vue";
export default {
  components: {
    TitleLine,
    TitleMore
  },
  name: 'Helppublic',
  data () {
      return {
        tableData: [],
        page: 0,
        size:10,
        total: 0,
        type:'jzgs_rdjz',
      }
    },
    created () {
      this.gethelpdate()
    },
    methods : {
      gdHandleTab(index){
        switch (index) {
          case 0:
            this.type = 'jzgs_rdjz';
            this.page = 0;
          break;
           case 1:
            this.type = 'jzgs_tsygjj';
            this.page = 0;
          break;
           case 2:
            this.type = 'jzgs_xtsjj';
            this.page = 0;
          break;
           case 3:
            this.type = 'jzgs_baswj';
            this.page = 0;
          break;
        }
        this.gethelpdate()
      },
    gethelpdate () {
       apiUrls.gethelpdate({node: this.type,size:this.size,page:this.page}).then(res=>{
        this.tableData = res.results.data;
        this.total = res.results.pager.totalElements;
      })
    },
    handleCurrentChange(val) {
      this.page = val;
      apiUrls.gethelpdate({node: this.type, page:this.page -1}).then(res=>{
        this.total = res.results.pager.totalElements;
        this.tableData= res.results.data
        })
      }
    }
}
</script>

<style lang="less" scoped>
.helppublic {
  background-color: #f5f5f5;
  .helppublic-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
    .helppublic-title  {
          margin-top: 10px;
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
          }
        }
     .helppubliccontent {
            min-height: 400px;
            background-color: #ffffff;
            overflow: hidden;
            box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
            h2 {
              font-size: 20px;
              color: #555454;
              font-weight: bold;
              text-align:center;
              margin-top: 41px;
              margin-bottom: 16px;
            }
            p {
                text-align: center;
                margin-bottom: 44px;
            }
            .tabcontent {
               margin: 0 55px 47px 50px;
              /deep/ .cell {
                    text-align: center;
                    color: #333333
                    }
            }
            .pagination {
             margin-bottom: 40px;
            .bs-pagination {
             width: 656px;
            }
        }
        }
  }
}
</style>
<style>
.el-table thead {
  color: #333333 !important;
  font-weight: bold !important;
}

</style>
